/*

Template: Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE:  This file includes all shortcode css. If you don't want to use any shortcode from below. You can simply remove that line from this file.

*/
 
/*shortcode*/
@import "accordion.scss";
@import "action-box.scss";
@import "blockquote.scss";
@import "bootstrap-typography";
@import "button";
@import "clients";
@import "contact-form";
@import "countdown";
@import "counter";
@import "divider";
@import "dropcap";
@import "feature-text";
@import "list-style";
@import "nice-select";
@import "owl-carousel";
@import "page-title";
@import "pie-chart";
@import "pricing";
@import "progress-bar";
@import "section-title";
@import "social-icons";
@import "tabs";
@import "team";
@import "testimonial";
@import "side-panel";
@import "onload-modal";
