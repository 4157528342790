/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Pricing CSS.
*/

/***************************
        Our Pricing
***************************/
.pricing-top { box-shadow: 0 0 40px rgba(0, 0, 0, 0.05); padding: 30px; border-top: 8px solid #1a1a1a; border-radius: 6px; }
.pricing-prize h2 span { font-size: 18px; }
.pricing-prize { margin: 30px 0; }
.pricing-content {   width: 70%; margin: 40px auto 0; }
.pricing-content .pricing-table-list ul li { line-height: 56px; font-weight: 600; }
.pricing-content .pricing-table-list ul li i { width: 25px; display: inline-block; color: #84ba3f; }
.pricing-content .pricing-table-list ul li i.fa-times { color: red; }
.pricing-content .pricing-table-list ul li span { margin-top: 16px; text-align: center; font-size: 14px; line-height: 24px; box-shadow: 0 6px 14px rgba(0, 0, 0, 0.09); width: 24px; height: 24px; background: transparent; border-radius: 50%; }
.pricing-content .pricing-table-list ul li span i { padding-right: 0; }
.pricing-table.active { margin-top: -7px;  }
.pricing-table.active .pricing-top { border-top: 16px solid #84ba3f; box-shadow: 0 0 50px rgba(0, 0, 0, 0.05); padding-bottom: 30px; }
.pricing-table.active .pricing-top a.button { padding: 12px 60px; font-size: 20px; }
.pricing-table.active .pricing-prize { margin: 30px 0 20px; }

/*style-2*/
.pricing-table.boxed {  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05); padding: 30px; border-top: 8px solid #1a1a1a; border-radius: 6px;  }
.pricing-table.boxed .pricing-top { box-shadow: none; border: 0; padding: inherit; border-radius: inherit; padding: 0; }
.pricing-table.boxed .pricing-content { width: inherit; margin:0 ; margin-top: 20px; }
.pricing-table.active.boxed { border-top: 16px solid #84ba3f; box-shadow: 0 0 50px rgba(0, 0, 0, 0.05); }
 
/*price simple*/
.price { list-style-type: none; border: 1px solid #eee; margin: 0; padding: 0;  -webkit-transition: 0.3s; transition: 0.3s; border:0; }
.price:hover {  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05); }
.price .header { background-color: #111; color: #ffffff; font-size: 25px; }
.price li { border-bottom: 1px solid #eee; padding: 20px; text-align: center; }
.price .grey { background-color: #eee; font-size: 20px; }
.price.active .header { background: #84ba3f; }
 
/*membership-pricing*/
.membership-pricing-table { width: 100%; }
.membership-pricing-table table { width: 100%; }
.membership-pricing-table table .icon-no,.membership-pricing-table table .icon-yes { font-size: 22px; }
.membership-pricing-table table .icon-no { color: #a93717; }
.membership-pricing-table table .icon-yes { color: #209e61; }
.membership-pricing-table table .plan-header { text-align: center; font-size: 48px; border: 1px solid #e2e2e2; padding: 25px 0; }
.membership-pricing-table table .plan-header-free { background-color: #eee; color: #555; }
.membership-pricing-table table .plan-header-blue { color: #fff; background-color: #111; border-color: #000; }
.membership-pricing-table table .plan-header-standard { color: #fff; background-color: #84ba3f; border-color: #84ba3f; }
.membership-pricing-table table td { text-align: center; width: 15%; padding: 7px 10px; background-color: #fafafa; font-size: 14px; -webkit-box-shadow: 0 1px 0 #fff inset;
    box-shadow: 0 1px 0 #fff inset; }
.membership-pricing-table table,.membership-pricing-table table td { border: 1px solid #ebebeb; }
.membership-pricing-table table tr td:first-child { background-color: transparent; text-align: right; width: 14%; }
.membership-pricing-table table tr td:nth-child(5) { background-color: #FFF; }
.membership-pricing-table table tr:first-child td,.membership-pricing-table table tr:nth-child(2) td { -webkit-box-shadow: none; box-shadow: none; }
.membership-pricing-table table tr:first-child th:first-child { border-top-color: transparent; border-left-color: transparent; border-right-color: #e2e2e2; }
.membership-pricing-table table tr:first-child th .pricing-plan-name { font-size: 22px; }
.membership-pricing-table table tr:first-child th .pricing-plan-price { line-height: 35px; }
.membership-pricing-table table tr:first-child th .pricing-plan-price>sup { font-size: 45%; }
.membership-pricing-table table tr:first-child th .pricing-plan-price>i { font-size: 30%; }
.membership-pricing-table table tr:first-child th .pricing-plan-period { margin-top:  10px; font-size: 25%; }
.membership-pricing-table table .header-plan-inner { position: relative; }
.membership-pricing-table table .recommended-plan-ribbon { box-sizing: content-box; background-color: #ffffff; color: #84ba3f; position: absolute;
    padding: 3px 6px; font-size: 11px!important; font-weight: 500; left: -6px; top: -22px; z-index: 99; width: 100%; -webkit-box-shadow: 0 -1px #c2284c inset;
    box-shadow: 0 -1px #ffffff inset; text-shadow: 0 -1px #ffffff; }
.membership-pricing-table table .recommended-plan-ribbon:before { border: solid; border-color: #ffffff transparent; border-width: 6px 0 0 6px;
    bottom: -5px; content: ""; left: 0; position: absolute; z-index: 90; }
.membership-pricing-table table .recommended-plan-ribbon:after { border: solid; border-color: #ffffff transparent; border-width: 6px 6px 0 0;
    bottom: -5px; content: ""; right: 0; position: absolute; z-index: 90; }
.membership-pricing-table table .plan-head { box-sizing: content-box; background-color: #ff9c00; border: 1px solid #cf7300; position: absolute; top: -33px;
    left: -1px; height: 30px; width: 100%; border-bottom: none; }
.membership-pricing-table table td i.fa-times-circle-o { color: red; }