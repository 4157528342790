/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Features box CSS.
*/

/*************************************
             Features all
 **************************************/
.feature-text { text-align: center; }
.feature-text.text-left { text-align: left; }
.feature-text.text-left .feature-icon span, .feature-text.text-left .feature-icon i { padding-left: 0;  }
.feature-text.text-right .feature-icon span, .feature-text.text-right .feature-icon i { padding-right: 0; }
.feature-text.text-right { text-align: right; }
.feature-text .feature-icon span, .feature-text .feature-icon i { font-size: 40px; margin-bottom: 30px; display: inline-block; padding-left: 15px; transition: 0.5s ease-in-out; -webkit-transition: 0.5s ease-in-out; -o-transition: 0.5s ease-in-out; -moz-transition: 0.5s ease-in-out; }
.feature-text.round .feature-icon span, .feature-text.round .feature-icon i { width: 70px; height: 70px; line-height: 70px;  display: inline-block; font-size: 26px; border-radius: 50%; padding-left: 0px; }
.feature-text.feature-border .feature-icon span, .feature-text.feature-border .feature-icon i { color: #323232; background: transparent; border:2px solid #323232; padding-left: 0px; }
.feature-text.gray-icon .feature-icon span, .feature-text.gray-icon .feature-icon i {  color: #323232; background: #f6f7f8; padding-left: 0px; }
.feature-text.theme-icon .feature-icon span, .feature-text.theme-icon .feature-icon i { color: #ffffff; background: #84ba3f; padding-left: 0px; }
.feature-text.shadow .feature-icon span, .feature-text.shadow .feature-icon i {  color: #323232; background: #ffffff; box-shadow: 0 4px 20px rgba(0,0,0,0.09); padding-left: 0px; }
.feature-text.square .feature-icon span, .feature-text.square .feature-icon i { width: 70px; height: 70px; line-height: 70px;  display: inline-block; font-size: 26px; padding-left: 0px;}
.feature-text.text-center .feature-icon span, .feature-text.text-center .feature-icon i { padding-left: 0; }

/*left-icon*/
.feature-text.left-icon .feature-info { text-align: left; padding-left: 60px; }
.feature-text.left-icon .feature-icon { position: absolute; left: 0;  }

/*right-icon*/
.feature-text.right-icon .feature-info { text-align: right; padding-right: 60px; }
.feature-text.right-icon .feature-icon { position: absolute; right: 0;  }
 
.feature-text.left-icon.round .feature-info  { padding-left: 80px; }
.feature-text.right-icon.round .feature-info  { padding-right: 80px; }

.feature-text.left-icon.square .feature-info  { padding-left: 100px; }
.feature-text.right-icon.square .feature-info  { padding-right: 100px; }

 /*box-shadow*/
.feature-text.box-shadow { box-shadow: 0 0px 30px rgba(0,0,0,0.09); padding: 40px; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;  } 
.feature-text.box-shadow:hover { box-shadow: 0 0px 70px rgba(0,0,0,0.09); }
 
/*border-box*/
.feature-text.border-box { box-shadow: 0 0px 1px rgba(0,0,0,0.5); padding: 40px;}

/*button*/
.feature-text .button { margin-top: 10px; }
.feature-text.round:hover .feature-icon i, .feature-text.round:hover .feature-icon span { background: #84ba3f; color: #ffffff; border-color: #84ba3f; }
.feature-text.theme-icon:hover .feature-icon span, .feature-text.theme-icon:hover .feature-icon i { background: #323232; color: #ffffff; }
.feature-text.square:hover .feature-icon i, .feature-text.square:hover .feature-icon span { background: #84ba3f; color: #ffffff; border-color: #84ba3f; }
.feature-text.square.theme-icon:hover .feature-icon span, .feature-text.square.theme-icon:hover .feature-icon i { background: #323232; color: #ffffff; }

 /*feature-box*/
 .awesome-features .img-side img { position: absolute; top: -30px; left: 0; }
.feature-box {  border:1px solid #e6e6e6; padding: 1px; position: relative; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; z-index: 1; }
.feature-box-content { position: relative; padding: 30px; padding-bottom: 0; z-index: 9; }
.feature-box i { width: 100%; font-size: 30px; color: #84ba3f; padding: 0 10px;  -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s;}
.feature-box h4 { text-transform: none; padding:30px 0 0px; font-weight: 500; transition: all 0.3s ease-out 0s; }
.feature-box p {   transition: all 0.3s ease-out 0s; }
.feature-box a { font-size: 16px; font-weight: 300; color: #808080; margin: 25px 0 10px; padding: 0 30px; display: block; position: relative; z-index: 3; }
.feature-box .feature-box-img { bottom: 0; left: 0; opacity: 0; position: absolute; right: 0; top: 0; z-index: 0; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; z-index: 0; background-size: cover; background-position: center center; }
.feature-box .feature-border { position: absolute; background: #84ba3f; height: 0px; bottom: 0; left: 0; z-index:2; width: 100%; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s;  }
.feature-box:hover .feature-box-img, .feature-box.active .feature-box-img { opacity: 1; }
.feature-box:hover .feature-border, .feature-box.active .feature-border { height: 45px; }
.feature-box:hover i, .feature-box.active i { background: rgba(255, 255, 255, 0); color: #fff; }
.feature-box:hover h4, .feature-box.active h4, .feature-box:hover p, .feature-box.active p { color: #fff; }
.feature-box:hover a, .feature-box.active a {  color: #fff; }
.feature-box:hover:before, .feature-box.active:before { background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0; color: #fff; content: ""; display: inline-block; height: 100%;
  left: 0; position: absolute; top: 0; width: 100%; z-index: 1;  -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; }