/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains Progress Bar (Skill) CSS.
*/

/*************************
        skills 
*************************/ 
.skill{ position: relative; width:100%; height:7px; margin:40px 0 20px 0; background-color:#eceff8; border-radius: 4px;}
.skill:not(:first-child){margin-top:64px;}
.skill-bar  { position: relative; height:100%; border-radius: 4px; padding:0px; background:#84ba3f; box-shadow:none; transition: width .9s ease; -webkit-transition: width .9s ease; -o-transition: width .9s ease; -ms-transition: width .9s ease; -moz-transition: width .9s ease; }
 .progress-bar { position: relative; height:100%; border-radius: 4px; padding:0px; background-color: #84ba3f; box-shadow:none;   }
.skill-title{color:#353535; font-size:18px;  font-weight:500;  position:absolute; text-align:left; margin-left:0;  margin-top:-28px;}
.skill-title > i{margin-right:8px;}
.skill-bar .progress-number,
.skill-bar .progress-type {color:#353535; float:right; margin-top:-18px; position:absolute; right: 0; top:-10px; font-size:15px; font-weight: 600;}
.skill-bar .progress-number { right: 14px; } 

/* Dark Background */
.text-white .skill{background:rgba(255,255,255,0.25);}
.text-white .skill-title{color:#ffffff;}
.text-white .skill-bar .progress-number,
.text-white .skill-bar .progress-type{color:#ffffff;}
.theme-bg .skill-bar, .services-text-box-blue .skill-bar{background: #ffffff}

/* big Skills */
.skill.skill-big { height:12px; }

/* Medium Skills */
.skill.skill-medium { height:8px; }

/* Small Skills */
.skill.skill-small { height:3px; }

/* Dark Skills */
.skill.skill-dark .skill-bar { background:#353535; }