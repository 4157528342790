/*
Template:  Webster - Responsive Multi-purpose HTML5 Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file contains tab CSS.
*/

/*************************
        tab
*************************/
 /*tab 1*/
.tab .nav.nav-tabs { border: none; margin-bottom: 20px; }
.tab .nav.nav-tabs li { margin-bottom: 0px; float: none; display: inline-block; }
.tab .nav.nav-tabs li a { border: none; line-height: normal; border-radius: 0px; padding: 12px 30px; background: #f6f7f8; color: #353535; border:1px solid #eeeeee; }
.tab .nav.nav-tabs li a i { padding-right: 5px; }
.tab .nav.nav-tabs > li > a.active, .nav.nav-tabs > li > a.active:focus, .nav.nav-tabs > li > a.active:hover {  background: #84ba3f; color: #fff; border-color:transparent; }

/*nav-border*/
.nav-border .nav.nav-tabs li a { margin-right: 0; border:1px solid #eeeeee; background: transparent; }
.nav-border .nav.nav-tabs > li > a.active, .nav-border .nav.nav-tabs > li > a.active:focus, .nav-border .nav.nav-tabs > li > a.active:hover { color: #ffffff;  background: #84ba3f;  border-color:#84ba3f; }

/*tab-border*/
.tab-border .nav.nav-tabs { margin-bottom: 0; }
.tab-border .tab-content { padding: 20px; border:1px solid #eeeeee; margin-top: -1px; }
.tab-border .nav.nav-tabs > li > a.active, .tab-border .nav.nav-tabs > li > a.active:focus, .tab-border .nav.nav-tabs > li > a.active:hover { color: #84ba3f;  background: #ffffff !important;  border-color:#eeeeee; border-bottom:1px solid #ffffff; }

/*nav-center*/
.nav-center .nav.nav-tabs { text-align: center; display: block; }
.nav-right .nav.nav-tabs { text-align: right; display: block; }
.nav-right .tab-content { text-align: right; }

/*round*/
.round .nav.nav-tabs li a { border: none; line-height: normal; margin-right: 10px; border-radius: 90px; padding: 12px 30px; background: #f6f7f8; color: #353535; }

/*shadow*/
.shadow .nav.nav-tabs > li > a.active, .shadow .nav.nav-tabs > li > a.active:focus, .shadow .nav.nav-tabs > li > a.active:hover { color: #84ba3f;  background: #ffffff !important;  border-color:#eeeeee; border-bottom: 0; box-shadow:  0px 3px 10px rgba(0, 0, 0, 0.1); }

/*tab-vertical*/
.tab-vertical .nav-tabs { float: left; width: 25%; }
.tab-vertical .nav.nav-tabs li {  display: block; width: 100%; }
.tab-vertical .nav.nav-tabs li a { border-right: 0; border-top: 0; padding: 14px 30px; }
.tab-vertical .nav.nav-tabs li:first-child a { border-top: 1px solid #eeeeee; }
.tab-vertical .tab-content { background-color: #fff; border: 1px solid #e9e9e9; padding: 40px 30px; margin-left: 25%; line-height: 30px; margin-top: 0; }
.tab-vertical .tab-content p { line-height: 30px; }
.tab-vertical .nav.nav-tabs > li > a.active, .tab-vertical .nav.nav-tabs > li > a.active:focus, .tab-vertical .nav.nav-tabs > li > a.active:hover { border-bottom: 1px solid #eeeeee; }

/*vertical-right*/
.vertical-right .nav-tabs { float: right; }
.vertical-right .nav.nav-tabs li a { border-right: 1px solid #eeeeee; border-left: 0; }
.vertical-right .tab-content {  margin-right: 24.8%; margin-left: inherit; text-align: right; }

/* dropdown */
.tab-dropdown .tab-content { margin-top: 30px; }
.nav-pills>li>a.active, .nav-pills>li>a.active:focus, .nav-pills>li>a.active:hover { background: #84ba3f; color: #ffffff; }

/* nav border top */
.tab.nav-bt .nav li a { margin-right:0px !important; border-top: 2px solid transparent; border-left: 0; border-right: 0; border-bottom: 0;} 
.tab.nav-bt .nav.nav-tabs > li> a.active { background: #f6f7f8; border-color: transparent; color: #353535; border-top: 2px solid #84ba3f; }

/* nav border left */
.tab.nav-bl .nav li a{ margin-right:0px !important;} 
.tab.nav-bl .nav.nav-tabs > li > a { border-left: 4px solid transparent; border-right: none; border-bottom: none; border-top: none; }
.tab.nav-bl .nav.nav-tabs > li > a.active{ background: #f6f7f8; border-color: transparent; color: #353535; border-left: 4px solid #84ba3f; border-bottom: none !important; }

/* nav border right */
.tab.nav-br .nav li a{ margin-right:0px !important;} 
.tab.nav-br .nav.nav-tabs > li > a { border-right: 4px solid transparent; border-left: none; border-bottom: none; border-top: none; }
.tab.nav-br .nav.nav-tabs > li > a.active { background: #f6f7f8; border-color: transparent; color: #353535; border-right: 4px solid #84ba3f;  border-bottom: none !important; }